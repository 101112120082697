const DRAWER_MENU_LINK_SELECTOR = '.js-drawer-menu-link'
const DRAWER_MENU_MAIN_SELECTOR = '.js-drawer-menu-main'
const DRAWER_MENU_BACK_SELECTOR = '.js-drawer-menu-back-link'

const classes = {
  active: "active",
  visible: "visible",
}

export default () => {
  const drawerMenuItems = document.querySelectorAll(DRAWER_MENU_LINK_SELECTOR)
  const drawerMenuBackItems = document.querySelectorAll(DRAWER_MENU_BACK_SELECTOR)
  const drawerMenuMainEl = document.querySelector(DRAWER_MENU_MAIN_SELECTOR)

  if (drawerMenuItems.length) {
    drawerMenuItems.forEach((drawerMenuItem) => {
      drawerMenuItem.addEventListener('click', () => {
        if (drawerMenuMainEl) {
          drawerMenuMainEl.scrollTop = 0
        }
      })
    })
  }

  if (drawerMenuBackItems.length) {
    drawerMenuBackItems.forEach((drawerMenuBackItem) => {
      drawerMenuBackItem.addEventListener('click', () => {
        if (drawerMenuMainEl) {
          drawerMenuMainEl.scrollTop = 0
        }
      })
    })
  }

  function close() {
    const drawerMenu = document.querySelector("[data-drawer-menu]")
    const menuButton = document.querySelector(".header__icon-menu")
    if (!menuButton || !drawerMenu) return

    menuButton.setAttribute("aria-expanded", false)
    menuButton.setAttribute("aria-label", menuButton.getAttribute("data-aria-label-closed"))
    drawerMenu.classList.remove(classes.visible)
    drawerMenu.classList.remove(classes.active)
    document.body.setAttribute("mobile-menu-open", "false")
    document.body.setAttribute("data-fluorescent-overlay-open", "false")
  }

  const onResize = () => {
    const width = document.body.clientWidth
    if (width > 960) {
      close()
    }
  }

  if (typeof window !== 'undefined') {
    window.addEventListener("resize", onResize)
  }
}
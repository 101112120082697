export default () => {
  const body = document.querySelector('body')

  if (body.classList.contains('template-product')) {
    const productTitle = document.querySelector('.product__title')
    const productsObserver = new MutationObserver((mutationsList) => {
      mutationsList.forEach(function (mutation) {
        if (mutation.type === 'childList' && mutation.target.lastElementChild?.classList.contains('pswp--product-lightbox')) {
          const productLightbox = document.querySelector('.pswp--product-lightbox')
          productLightbox.setAttribute('aria-modal', true)
          productLightbox.setAttribute('aria-labelledby', 'product-title')
          if (productTitle.innerText !== '') {
            productLightbox.setAttribute('aria-label', productTitle.innerText)
          }
        }
      })
    })

    productsObserver.observe(body, {
      attributes: false,
      childList: true,
      subtree: true,
    })
  }
}
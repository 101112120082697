export default () => {
  const klaviyoFormEl = document.querySelector('.js-klaviyo-form')

  if (klaviyoFormEl) {
    const formElement = klaviyoFormEl.querySelector('form')
    const emailField = klaviyoFormEl.querySelector('input[type="text"]')
    const successMessage = klaviyoFormEl.querySelector('#ContactFooter-success')
    const errorMessage = klaviyoFormEl.querySelector('#ContactFooter-error')
    const emailErrorMessage = klaviyoFormEl.querySelector('#ContactFooter-email-error')
    const klaviyoListId = klaviyoFormEl.dataset.listId
    const emailError = klaviyoFormEl.querySelector('.js-input-error')

    function parseJSON (string) {
      let json = {}
      string = string.replace(/<!--.*-->/g, '')
      try {
        json = JSON.parse(string)
      } catch (e) {
        console.log(e)
      }
      return json
    }

    async function onFormSubmit (event) {
      event.preventDefault()

      if (!emailField) {
        return
      }

      this.query = {
        email: emailField.value,
        g: klaviyoListId
      }

      const isValid = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const checkValid = isValid.test(emailField.value)

      if (!checkValid) {
        event.preventDefault()
        emailField.classList.add('input-error')
        klaviyoFormEl.classList.add('form-error')
        if (emailError) {
          emailField.setAttribute('aria-describedby', 'text-input__error')
          emailError.innerHTML = 'Please enter a valid email address!'
        }
        return
      } else {
        klaviyoFormEl.classList.remove('form-error')
        if (emailError) {
          emailField.removeAttribute('aria-describedby')
          emailError.innerHTML = ''
        }
      }

      const query = Object.keys(this.query).map(k => {
        let value = this.query[k]
        return `${k}=${value}`
      }).join('&')

      fetch('https://manage.kmail-lists.com/ajax/subscriptions/subscribe', {
        method: 'POST',
        body: query,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          "cache-control": "no-cache"
        },
      })
        .then((response) => response.text())
        .then((responseText) => {
          const parsed = parseJSON(responseText)
          if (parsed && parsed['success']) {
            addDatalayer()
            showSuccess(true)
            showError('')
          } else {
            showError('Whoops, something went wrong')
            console.error('newsletter', parsed)
          }
        })
    }

    function showSuccess (isSuccessful) {
      if (successMessage) {
        successMessage.toggleAttribute('hidden', !isSuccessful)
      }

      if (isSuccessful && formElement) {
        formElement.classList.add('hidden')
      }
    }

    function showError (isError) {
      if (isError) {
        showSuccess(false)
      }

      if (errorMessage) {
        errorMessage.toggleAttribute('hidden', false)
        errorMessage.innerHTML = isError
      }
    }

    function showEmailError (isError) {
      if (isError) {
        showSuccess(false)
      }

      if (emailErrorMessage) {
        errorMessage.toggleAttribute('hidden', true)
        emailErrorMessage.toggleAttribute('hidden', false)
        emailErrorMessage.innerHTML = isError
      }
    }

    if (formElement) {
      formElement.addEventListener('submit', onFormSubmit)
    }

    function addDatalayer () {
      window.dataLayer.push({
        event: "form_submission",
        params: {
          form_name: "Footer subscribe",
          subject: "Email Subscription"
        }
      })
    }
  }
}
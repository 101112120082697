import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import videoButton from '../js/video-button'

export default () => {
  const BLOG_RECIPE_SELECTOR = '.js-blog-recipe'
  const BLOG_FILTER_TOGGLE_SELECTOR = '.js-blog-filter-toggle'
  const BLOG_FILTER_TEXT_SELECTOR = '.js-blog-filter-text'
  const BLOG_RECIPE_FILTER_SELECTOR = '.js-blog-recipes-filters'
  const BLOG_RECIPE_FILTER_INPUT_SELECTOR = '.js-blog-filters-input'
  const BLOG_FILTER_CLEAR_SELECTOR = '.js-blog-filter-clear'
  const BLOG_SORT_SELECTOR = '.js-blog-sort'
  const BLOG_SORT_TOGGLE_SELECTOR = '.js-blog-sort-toggle'
  const BLOG_SORT_RADIO_SELECTOR = '.js-blog-sort-radio'
  const BLOG_RECIPE_CLOSE_SELECTOR = '.js-blog-recipes-close'
  const ACTIVE_SORT_CLASS = 'is-sort-active'
  const ACCORDION_GROUP_SELECTOR = '.js-accordion-group'
  const ACCORDION_CONTENT_SELECTOR = '.js-accordion-content'
  const ACCORDION_BUTTON_SELECTOR = '.js-accordion-btn'
  const ACTIVE_CLASS = 'is-active'
  const ACCORDION_CONTENT_CLOSE_CLASS = 'is-close'
  const PAGINATED_SELECTOR = '.js-pagination-recipe'
  const BREAKPOINT_MOBILE = 960
  let isMobile = true

  const blogRecipeEl = document.querySelector(BLOG_RECIPE_SELECTOR)

  if (!blogRecipeEl) return

  const filterInit = (blogRecipeEl) => {
    const blogFilterToggleEl = blogRecipeEl.querySelector(BLOG_FILTER_TOGGLE_SELECTOR)
    const blogFilterTextEl = blogRecipeEl.querySelector(BLOG_FILTER_TEXT_SELECTOR)
    const blogRecipesFiltersEl = blogRecipeEl.querySelector(BLOG_RECIPE_FILTER_SELECTOR)
    const blogFilterInputsEls = blogRecipeEl.querySelectorAll(BLOG_RECIPE_FILTER_INPUT_SELECTOR)
    const blogFilterClearEls = blogRecipeEl.querySelectorAll(BLOG_FILTER_CLEAR_SELECTOR)
    const blogSortEl = blogRecipeEl.querySelector(BLOG_SORT_SELECTOR)
    const blogColseEls = blogRecipeEl.querySelectorAll(BLOG_RECIPE_CLOSE_SELECTOR)
    const listAccordion = blogRecipeEl.querySelectorAll(ACCORDION_GROUP_SELECTOR)
    const paginationEl = blogRecipeEl.querySelector(PAGINATED_SELECTOR)

    const blogFilterToggle = (event) => {
      event.preventDefault()

      blogRecipeEl.classList.toggle(ACTIVE_CLASS)

      if (blogRecipesFiltersEl) {
        blogRecipesFiltersEl.classList.toggle(ACTIVE_CLASS)
      }

      if (blogFilterTextEl) {
        const { text, textHide } = blogFilterTextEl.dataset

        if (blogRecipesFiltersEl.classList.contains(ACTIVE_CLASS)) {
          blogFilterTextEl.textContent = textHide
        } else {
          blogFilterTextEl.textContent = text
        }
      }
    }

    const filterChange = (event) => {
      const value = event.target.value
      predictiveArticle(value)
    }

    const clearFilters = (el) => {
      if (!el) return
      el.addEventListener('click', () => {
        const url = el.dataset?.filterClear
        const newUrl = `${url}${window.location.search}`
        predictiveArticle(newUrl)
      })
    }

    const blogSort = (el) => {
      const buttonSortEl = el.querySelector(BLOG_SORT_TOGGLE_SELECTOR)
      const radioSortEl = el.querySelectorAll(BLOG_SORT_RADIO_SELECTOR)

      if (buttonSortEl) {
        buttonSortEl.addEventListener('click', () => {
          el.classList.toggle(ACTIVE_CLASS)
          blogRecipeEl.classList.toggle(ACTIVE_SORT_CLASS)

          if (blogRecipeEl.classList.contains(ACTIVE_SORT_CLASS) && isMobile) {
            disablePageScroll(document.body)
          }
        })
      }

      if (radioSortEl && radioSortEl.length) {
        radioSortEl.forEach(item => {
          item.addEventListener('change', () => {
            const newUrl = addQueryVar('sort_by', item.value)
            predictiveArticle(newUrl)
          })
        })
      }
    }

    const predictiveArticle = async (url) => {
      const sectionId = blogRecipeEl.dataset.sectionId
      if (!url && sectionId) return

      const fetchUrl = addQueryVar('section_id', sectionId, url)

      try {
        await fetch(fetchUrl)
        .then(res => res.text())
        .then(res => {
          const domparser = new window.DOMParser()
          const resDoc = domparser.parseFromString(res, 'text/html')
          const updatedPartialEl = resDoc.querySelector(BLOG_RECIPE_SELECTOR)
          blogRecipeEl.innerHTML = updatedPartialEl.innerHTML

          const blogRecipesFiltersEl = blogRecipeEl.querySelector(BLOG_RECIPE_FILTER_SELECTOR)
          const blogFilterTextEl = blogRecipeEl.querySelector(BLOG_FILTER_TEXT_SELECTOR)
          const blogSortEl = blogRecipeEl.querySelector(BLOG_SORT_SELECTOR)

          if (blogSortEl && blogRecipeEl.classList.contains(ACTIVE_SORT_CLASS)) {
            blogSortEl.classList.add(ACTIVE_CLASS)
          }

          if (blogRecipesFiltersEl && blogRecipeEl.classList.contains(ACTIVE_CLASS)) {
            blogRecipesFiltersEl.classList.add(ACTIVE_CLASS)
          }

          if (blogFilterTextEl) {
            const { text, textHide } = blogFilterTextEl.dataset

            if (blogRecipesFiltersEl.classList.contains(ACTIVE_CLASS)) {
              blogFilterTextEl.textContent = textHide
            } else {
              blogFilterTextEl.textContent = text
            }
          }
          window.history.pushState({}, '', url)
          filterInit(blogRecipeEl)
          videoButton()
        })
      } catch (error) {
        console.log('error', error)
      }
    }

    const addQueryVar = (key, value, url = window.location.href) => {
      const _getQueryPattern = (key) => new RegExp(`[?&]${key}=([^&]+)`)
      const removeQueryVar = (key, url = window.location.href) => {
        const idx = url.indexOf('?')

        if (idx === -1) {
          return url
        }

        const pattern = _getQueryPattern(key)
        const replaced = url.replace(pattern, '').replace(/\?$/, '')

        if (replaced.indexOf('&') !== -1 && replaced.indexOf('?') === -1) {
          return replaced.replace(/&/, '?')
        }

        return replaced
      }

      const removed = removeQueryVar(key, url)
      if (!value && value !== 0) {
        return removed
      }
      return `${removed}${removed.indexOf('?') === -1 ? '?' : '&'}${key}=${value}`
    }

    const toggleDescription = (item) => {
      const accordion = item.parentNode
      const content = accordion.querySelector(ACCORDION_CONTENT_SELECTOR)
      if (accordion) accordion.classList.toggle(ACCORDION_CONTENT_CLOSE_CLASS)
      if (accordion && !accordion.classList.contains(ACCORDION_CONTENT_CLOSE_CLASS)) {
        item.setAttribute('aria-expanded', 'true')
        content.setAttribute('aria-expanded', 'false')
        content.style.height = content.children[0].scrollHeight + 'px'
      } else {
        item.setAttribute('aria-expanded', 'false')
        content.setAttribute('aria-expanded', 'true')
        content.style.height = '0px'
      }
    }

    const accordion = (list) => {
      list.forEach(item => {
        const button = item.querySelector(ACCORDION_BUTTON_SELECTOR)
        if (button) {
          const content = item.querySelector(ACCORDION_CONTENT_SELECTOR)
          if (content && !content.classList.contains(ACCORDION_CONTENT_CLOSE_CLASS)) {
            const content = item.querySelector(ACCORDION_CONTENT_SELECTOR)
            if (content) content.style.height = content.children[0].scrollHeight + 'px'
          }
          button.addEventListener('click', () =>{
            toggleDescription(button)
          })
        }
      })
    }

    const paginatedResults = (el) => {
      const paginationItemEls = el.querySelectorAll('a')
      if (paginationItemEls && paginationItemEls.length) {
        paginationItemEls.forEach(item => {
          item.addEventListener('click', (e) => {
            const href = item.getAttribute('href')
            predictiveArticle(href)
            e.preventDefault()
            e.stopPropagation()
          })
        })
      }
    }

    const resizeHandler = () => {
      if (window.innerWidth > BREAKPOINT_MOBILE) {
        isMobile = false
        enablePageScroll(document.body)
      } else {
        isMobile = true
      }
    }

    if (blogFilterToggleEl) {
      blogFilterToggleEl.addEventListener('click', (e) => {
        if (isMobile) disablePageScroll(document.body)
        blogFilterToggle(e)
      })
    }

    if (blogFilterInputsEls && blogFilterInputsEls.length) {
      blogFilterInputsEls.forEach(item => {
        item.addEventListener('change', filterChange)
      })
    }

    if (blogFilterClearEls && blogFilterClearEls.length) {
      blogFilterClearEls.forEach(item => {
        clearFilters(item)
      })
    }

    if (blogSortEl) {
      blogSort(blogSortEl)
    }

    if (blogColseEls && blogColseEls.length) {
      blogColseEls.forEach(item => {
        item.addEventListener('click', (item) => {
          enablePageScroll(document.body)
          if (blogRecipeEl.classList.contains(ACTIVE_SORT_CLASS)) {
            if (blogSortEl) {
              blogSortEl.classList.toggle(ACTIVE_CLASS)
              blogRecipeEl.classList.toggle(ACTIVE_SORT_CLASS)
            }
          } else {
            blogFilterToggle(item)
          }
        })
      })
    }

    if (listAccordion && listAccordion.length) {
      accordion(listAccordion)
    }

    if (paginationEl) {
      paginatedResults(paginationEl)
    }

    window.addEventListener('resize', resizeHandler)

    resizeHandler()
  }

  filterInit(blogRecipeEl)
}

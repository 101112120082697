export default () => {
  setTimeout(function () {
    const scrollBar = document.querySelectorAll('.js-swiper-scrollbar')
    if (scrollBar && scrollBar.length) {
      scrollBar.forEach(itemScroll => {
        const sliderScrollDrag = itemScroll.querySelector('.swiper-scrollbar-drag')
        if (sliderScrollDrag) {
          sliderScrollDrag.setAttribute('tabindex', '0')
        }
      })
    }
  }, 1500)

  window.addEventListener('keydown', (event) => {
    if (event.key === 'ArrowRight' || event.key === 'ArrowLeft' && event.target.classList.contains('swiper-scrollbar-drag') && event.target.hasAttribute('tabindex')) {
      const parentID = event.target.offsetParent.dataset.id
      const navigation = document.querySelector('#' + parentID)
      const navPrev = navigation ? navigation.querySelector('[data-prev]') : null
      const navNext = navigation ? navigation.querySelector('[data-next]') : null

      if (navPrev || navNext && event.target.hasAttribute('tabindex')) {
        switch (event.key) {
          case "ArrowRight":
            if (navNext.getAttribute('disabled') !== '' && navNext) {
              navNext.click()
            }
            break;
          case "ArrowLeft":
            if (navPrev.getAttribute('disabled') !== '' && navPrev) {
              navPrev.click()
            }
            break;
        }
      }
    }
  })
}
export default () => {
  document.addEventListener("DOMContentLoaded", function () {
    const videoHeroModalEls = document.querySelectorAll('.js-video-hero-modal')

    videoHeroModalEls.length && videoHeroModalEls.forEach(videoHeroModalEl => {
      const videoEl = videoHeroModalEl.querySelector('video');
      const audioControl = videoHeroModalEl.querySelector('.js-audio-control')
      const buttonPlayPause = videoHeroModalEl.querySelector('.js-button-video')
      const buttonOpenModal = videoHeroModalEl.querySelector('.js-open-modal')
      const closeModalEls = videoHeroModalEl.querySelectorAll('.js-close-modal')
      const buttonCloseModal = videoHeroModalEl.querySelector('button.js-close-modal')
      const trackEl = videoHeroModalEl.querySelector('track')

      if (videoEl && trackEl) {
        trackEl.oncuechange = e => {
          const cues = [...e.target.track.activeCues]
          console.log(e)
          console.log(cues)
          console.log(videoEl.currentTime, videoEl)
        }
      }

      if (videoEl && buttonOpenModal) {
        buttonOpenModal.addEventListener('click', (e) => {
          e.preventDefault()
          openModal(videoHeroModalEl, buttonCloseModal)
          videoEl.play()
        })
      }
      if (videoEl && closeModalEls) {
        closeModalEls.forEach(buttonCloseModal => {
          buttonCloseModal.addEventListener('click', (e) => {
            e.preventDefault()
            closeModal(videoHeroModalEl)
            videoEl.pause()

            if (buttonPlayPause) {
              buttonPlayPause.classList.add('played')
            }
          })
        })
      }

      if (videoEl && audioControl) {
        audioControl.addEventListener('click', function () {
          if (audioControl.classList.contains('muted')) {
            videoEl.volume = 1
          } else {
            videoEl.volume = 0
          }
          audioControl.classList.toggle('muted')
        })
      }
    })
  })

  const openModal = (videoSection, buttonCloseModal) => {
    videoSection.classList.add('active')
    document.body.classList.add('video-modal-active')
    buttonCloseModal.focus()
  }

  const closeModal = (videoSection) => {
    videoSection.classList.remove('active')
    document.body.classList.remove('video-modal-active')
  }
}
export default () => {
  const timelineEls = [...document.querySelectorAll('.timeline .swiper')]
  if (timelineEls.length) {
    timelineEls.forEach((carousel, index) => {
      import(flu.chunks.swiper /* @vite-ignore */).then(function (_ref) {
        const Swiper = _ref.Swiper
        const Scrollbar = _ref.Scrollbar
        const Navigation = _ref.Navigation
        const Mousewheel = _ref.Mousewheel
        const swiper = new Swiper(carousel, {
          modules: [Scrollbar, Mousewheel, Navigation],
          spaceBetween: 0,
          mousewheel: {
            forceToAxis: true
          },
          breakpoints: {
            1440: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 3,
            },
            320: {
              slidesPerView: 2,
            }
          },
          navigation: {
            nextEl: '.slider-nav-button-next',
            prevEl: '.slider-nav-button-prev'
          },
          scrollbar: {
            el: '.js-scrollbar',
            draggable: true
          },
          on: {
            click: function () {
              this.slides.forEach(item => item.classList.remove('active'))
              this.clickedSlide.classList.add('active')
            },
            init: function () {
              this.slides[0].classList.add('active')
            }
          }
        })
      })
    })
  }
}
const LOADED_CLASS = 'showcase-loaded'

export default () => {
  const showcaseEls = document.querySelectorAll('.js-showcase')
  showcaseEls.length && showcaseEls.forEach(el => {
    const embedEl = el.querySelector('.js-showcase-embed')
    const initShowcaseScript = () => {
      return new Promise((resolve, reject) => {
        const galleryId = embedEl.getAttribute('data-gallery-id')
        if (galleryId) {
          const embedScript = document.createElement('script')
          embedScript.setAttribute('data-gallery-id', galleryId)
          embedScript.setAttribute('data-force-limit', 10)
          embedScript.setAttribute('type', 'text/javascript')
          embedScript.src = `https://showcase.abovemarket.com/embed/gallery/${galleryId}`
          embedScript.onload = resolve
          embedScript.onerror = reject
          embedEl.appendChild(embedScript)
        }
      })
    }

    const initObserver = (elm, cb) => {
      const observer = new window.MutationObserver((mutations) => {
        if (mutations.length) {
          if (typeof cb === 'function') {
            cb()
          }
        }
      })

      if (elm) {
        observer.observe(elm, {
          childList: true
        })
      }
    }

    if (window.location.pathname === '/') {
      let loadedScript = false
      document.addEventListener('scroll', () => {
        const top = window.pageYOffset || document.documentElement.scrollTop
        if (top > 100 && loadedScript === false) {
          loadedScript = true
          initShowcaseScript().then(() => {
            initObserver(embedEl, el.classList.add(LOADED_CLASS))
          })
        }
      })
    }
  })
}

export default () => {
  const SENDGRID_FORM_EL = '.js-contact-form-sendgrid';
  const sendgridFormEl = document.querySelector(SENDGRID_FORM_EL);

  if (sendgridFormEl) {
    const formElement = sendgridFormEl.querySelector('form')
    const dataFormAction = sendgridFormEl.getAttribute('data-form-action')
    const dataRecipientEmail = sendgridFormEl.getAttribute('data-recipient-email')
    const buttonEl = formElement.querySelector('[type="submit"]')

    async function onFormSubmit (event) {
      event.preventDefault()

      const inputEls = formElement.querySelectorAll('[required]')
      const isValid = checkRequired(inputEls)

      const isValidateMail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const checkValidMail = isValidateMail.test(dataRecipientEmail)

      if (!dataFormAction || !checkValidMail || !isValid) {
        return
      }

      const formData = new FormData(formElement)
      formData.append('recipientEmail', dataRecipientEmail)
      const formEntries = formData.entries()
      const data = Object.assign(...Array.from(formEntries, ([x, y]) => ({ [x] : y })));
      const headers = new Headers()
      headers.append('Content-Type', 'application/json')
      const options = {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
        mode: 'cors'
      }
      buttonEl.disabled = true
      fetch(dataFormAction, options)
        .then(response => response)
        .then(result => {
          responseHandler(result)
          buttonEl.disabled = false
        })
    }

    if (buttonEl) {
      buttonEl.addEventListener('click', onFormSubmit)
    }

    const responseHandler = (data) => {
      if (data.status === 200) {
        const successMessage = formElement.querySelector('.js-contact-form-subscribed');
        if (successMessage) successMessage.style.display = 'block';
        return
      } else {
        const errorMessage = formElement.querySelector('.js-contact-form-error');
        if (errorMessage) errorMessage.style.display = 'block';
      }
    }

    const checkRequired = (inputArr) => {
      let isValidate = true
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      inputArr.forEach((input) => {
        if (input.value.trim() === '') {
          isValidate = false
          showError(input)
          if (input.type.toLowerCase() === 'email') {
            input.focus()
          }
        } else {
          showSuccess(input)
          if (input.type.toLowerCase() === 'email' ) {
            if(re.test(input.value.trim())) {
              showSuccess(input)
            } else {
              isValidate = false
              showError(input)
            }
          }
        }
      })
      if (isValidate) {
        return true
      }
      return false
    }

    const showSuccess = (input) => {
      input.classList.remove('input-error')
      const formControl = input.closest('.contact-form__input-wrapper')

      if (formControl && formControl.classList.contains('error')) {
        formControl.classList.remove('error')
      }
    }

    const showError = (input, message) => {
      input.classList.add('input-error')
      const formControl = input.closest('.contact-form__input-wrapper')
      if (formControl) formControl.classList.add('error')
      const small = formControl.querySelector('small')
      if (small && message) small.innerText = message
    }
  }
}
